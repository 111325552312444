export const PUBLIC_REPORT = (hash) => `/report/${hash}`;
export const PROJECT_REPORT = (id) => `/project/${id}/indepth`;
export const PROJECT_WS = (projectId) => `/api/v1/projects/${projectId}`;
export const MEDIA_WS = (projectId) => `/api/v1/projects/showcase/${projectId}`;

export const KEYWORD_WS = (keyword) => `/api/v1/keywords/showcase/${keyword}`;
export const KEYWORD_EMOTION_WS = (keyword) =>
  `/api/v1/keywords-emotion/showcase/${keyword}`;

export const ROUTES = {
  OOPS: "/oops",
  ARF: "/arf",
  HOME: "/",
  HOME_REDIRECT: "https://sensia.ai",
  PUBLIC_REPORT: "/report/:hash",
  FEATURES: "/features",
  BENEFITS: "/benefits",
  PRICING: "/pricing",
  PLAN_PRICING: "/plan-pricing",
  PLAN_PRICING_WS: "/api/v1/plan",
  PROJECT_WS: "/api/v1/projects/",
  MEDIA_WS: "/api/v1/projects/showcase/",

  KEYWORD_WS: "/api/v1/keywords/showcase/",
  KEYWORD_EMOTION_WS: "/api/v1/keywords-emotion/showcase/",
  ACCELERATE_USE_OF_AMAZON_ARTICLE: "/accelerate_use_of_amazon",
  ACCELERATE_USE_OF_VERBATIM_ARTICLE: "/accelerate_use_of_verbatim",
  BENEFITS_OF_VIDEO_SURVEY_ARTICLE: "/benefits_of_video_survey",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
};
