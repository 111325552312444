import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "../app/helpers";
import moment from "moment-timezone";

import { ROUTES } from "./routing";

import {
  ToastProvider,
  SlideSlideToastRoot,
  GlossyToastContent,
} from "react-cooked-bread";

import LoadingView from "../views/loading/LoadingView";
import ConnectivityListener from "../listeners/ConnectivityListener";
import ToastedComponent from "../components/ToastedComponent";
import Error500View from "../views/errors/Error500View";

import PublicRoute from "../components/PublicRoute";
import ScrollToTop from "../components/ScrollToTop";
import { hotjar } from "react-hotjar";
import { globalConstants } from "./constants";
import ProjectReportView from "../views/projectReport/ProjectReportView";
import PlanPricingView from "../views/pricing/PlanPricingView";
import Home from "../views/Home";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "Now",
    ss: "Now",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    w: "1 week",
    ww: "%d weeks",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

const App = () => {
  if (globalConstants.ENV === "prod") {
    hotjar.initialize(2658164, 6);
  }
  return (
    <ToastProvider
      toastRoot={SlideSlideToastRoot}
      toastContent={GlossyToastContent}
    >
      <Suspense fallback={<LoadingView />}>
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            <PublicRoute exact path={ROUTES.HOME} component={Home} />
            <PublicRoute
              exact
              path={ROUTES.PLAN_PRICING}
              component={PlanPricingView}
            />
            <PublicRoute
              path={ROUTES.PUBLIC_REPORT}
              component={ProjectReportView}
            />
            {/* Catch all route */}
            <PublicRoute path={ROUTES.ARF} component={Error500View} />
            <Redirect from="*" to={ROUTES.HOME} />
          </Switch>
        </Router>
        <ToastedComponent component={ConnectivityListener} />
      </Suspense>
    </ToastProvider>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(App);
