import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";

import SwitchPricing from "../switchPricing/SwitchPricing";

// IMG IMPORT
import Arrow from "../../res/images/icons/path-4.svg";
// CSS
import "./planPricingBody.css";
import PlanPricingBox from "./PlanPricingBox";

class PlanPricingBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      yearly: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onChange = () => {
    this.setState({
      yearly: !this.state.yearly,
    });
  };

  onClick = () => {
    window.open("https://sensia.ai/contact-sales", "_self");
  };

  render() {
    const { t, item_list } = this.props;
    let proAdvantage = {};
    let businessAdvantage = {};
    let freeAdvantage = {};
    let entrepriseAdvantage = {};

    if (item_list) {
      for (const item of item_list) {
        if (item.key === "PRO") {
          proAdvantage = {
            title: t(item.key),
            planType: "Pro",
            monthlyPrice: item.monthly_price,
            yearlyMonthlyPrice: item.yearly_price,
            currency_code: item.currency_code,
            subpriceYearly: t("/month, when billed monthly"),
            subtitle: t("for individuals"),
            yearly_price: item.yearly_price,
            price: item.monthly_price,
            subprice: t("/year (-20%)"),
            advantages: [
              {
                advantage: [
                  t("Up to 3 projects"),
                  t("Insights Summaries"),
                  t("In-Depth Analytics"),
                  t("Reviews Inspector"),
                  t("Export (.csv) and sharing"),
                  t("Up to 1 custom class"),
                ],
              },
              {
                title: t("Datasources collection / Project"),
                advantage: [
                  t("Up to 3 sources per project"),
                  t("All sources including Walmart, Costco, Sephora etc"),
                  t("One language per source support"),
                ],
              },
              {
                title: t("Data import"),
                advantage: [t("Surveys (.txt, .csv, .xlsx)")],
              },
            ],
          };
        }
        if (item.key === "BUSINESS") {
          businessAdvantage = {
            title: t(item.key),
            planType: "Business",
            monthlyPrice: item.monthly_price,
            yearly_price: item.yearly_price,
            yearlyMonthlyPrice: item.monthly_price,
            currency_code: item.currency_code,
            subpriceYearly: t("/month, when billed monthly"),
            subtitle: t("for teams"),
            price: item.monthly_price,
            subprice: t("/year (-20%)"),
            advantages: [
              {
                advantage: [
                  t("Up to 5 projects"),
                  t("Insights Summaries"),
                  t("In-Depth Analytics"),
                  t("Reviews Inspector"),
                  t("Export (.csv) and sharing"),
                  t("Up to 4 custom classes"),
                  t("Up to 3 users"),
                ],
              },
              {
                title: t("Datasources collection / Project"),
                advantage: [
                  t("Up to 5 sources per project"),
                  t("All sources including Walmart, Costco, Sephora etc"),
                  t("Mixed languages support"),
                ],
              },
              {
                title: t("Data import"),
                advantage: [t("Surveys (.txt, .csv, .xlsx)")],
              },
            ],
          };
        }
        entrepriseAdvantage = {
          title: t("ENTERPRISE"),
          planType: "Enterprise",
          monthlyPrice: null,
          yearlyMonthlyPrice: null,
          subpriceYearly: t(
            "To get a price estimate for Enterprise plan, please contact our sales team."
          ),
          subtitle: t("for companies"),
          price: "Custom plan",
          subprice: t("/ user / volume of data"),
          advantages: [
            {
              advantage: [
                t("Unlimited projects"),
                t("Insights Summaries"),
                t("In-Depth Analytics"),
                t("Reviews Inspector"),
                t("Exports (.csv)"),
                t("Unlimited custom classes"),
                t("Unlimited users"),
              ],
            },
            {
              title: t("Datasources collection / Project"),
              advantage: [
                t("Up to 10 sources per project"),
                t("All sources including Walmart, Costco, Sephora etc"),
                t("Cloud / Internal apps integration"),
                t("Other eCommerce reviews"),
              ],
            },
            {
              title: t("Data import"),
              advantage: [
                t("Surveys (.txt, .csv, .xlsx)"),
                t("Business Intelligence"),
                t("Chat conversations"),
                t("Webservices"),
              ],
            },
          ],
        };
        freeAdvantage = {
          title: t("FREE"),
          planType: "Free",
          monthlyPrice: 0,
          yearlyMonthlyPrice: 0,
          subpriceYearly: "",
          subtitle: "",
          price: "0",
          subprice: t("Good to start and experiment"),
          advantages: [
            {
              advantage: [
                t("1 project"),
                t("Default classes"),
                t("Reviews Inspector"),
              ],
            },
            {
              title: t("Datasources collection / Project"),
              advantage: [
                t("Up to 2 sources per project"),
                t("Youtube videos and Amazon Product URL sources"),
                t("One language support"),
              ],
            },
          ],
        };
      }
    }

    return (
      <Fragment>
        <div className="plan-pricing-showcase central-content-1260">
          <h1 className="title">{t("Plans & pricing")}</h1>
          <p className="sub-title">
            {t("Sign up for free. Upgrade as you grow.")}
          </p>
          <div className="switch-btn">
            <SwitchPricing onClick={this.onChange} yearly={this.state.yearly} />
          </div>
          <div className="vingt-off">
            <p className="save-purcent">{t("Save 20%")}</p>
            <img className="icon-arrow" src={Arrow} alt="" />
          </div>
          <div className="content-4-columns-centered box position-relative">
            <div className="content-column no-hidden container-pricing-box">
              <PlanPricingBox
                planType={freeAdvantage.planType}
                title={freeAdvantage.title}
                price={freeAdvantage.price}
                subprice={freeAdvantage.subprice}
                advantages={freeAdvantage.advantages}
                yearly={this.state.yearly}
                monthlyPrice={freeAdvantage.monthlyPrice}
                yearlyMonthlyPrice={freeAdvantage.yearlyMonthlyPrice}
                subpriceYearly={freeAdvantage.subpriceYearly}
              />
              <PlanPricingBox
                planType={proAdvantage.planType}
                title={proAdvantage.title}
                subtitle={proAdvantage.subtitle}
                price={proAdvantage.price}
                subprice={proAdvantage.subprice}
                advantages={proAdvantage.advantages}
                recommended={true}
                yearly={this.state.yearly}
                monthlyPrice={proAdvantage.monthlyPrice}
                yearly_price={proAdvantage.yearly_price}
                yearlyMonthlyPrice={proAdvantage.yearlyMonthlyPrice}
                subpriceYearly={proAdvantage.subpriceYearly}
                currency_code={proAdvantage.currency_code}
              />
              <PlanPricingBox
                planType={businessAdvantage.planType}
                title={businessAdvantage.title}
                subtitle={businessAdvantage.subtitle}
                price={businessAdvantage.price}
                subprice={businessAdvantage.subprice}
                advantages={businessAdvantage.advantages}
                yearly={this.state.yearly}
                monthlyPrice={businessAdvantage.monthlyPrice}
                yearly_price={businessAdvantage.yearly_price}
                yearlyMonthlyPrice={businessAdvantage.yearlyMonthlyPrice}
                subpriceYearly={businessAdvantage.subpriceYearly}
                currency_code={businessAdvantage.currency_code}
              />
              <PlanPricingBox
                planType={entrepriseAdvantage.planType}
                title={entrepriseAdvantage.title}
                subtitle={entrepriseAdvantage.subtitle}
                price={entrepriseAdvantage.price}
                subprice={entrepriseAdvantage.subprice}
                advantages={entrepriseAdvantage.advantages}
                yearly={this.state.yearly}
                onClick={this.onClick}
                monthlyPrice={entrepriseAdvantage.monthlyPrice}
                yearlyMonthlyPrice={entrepriseAdvantage.yearlyMonthlyPrice}
                subpriceYearly={entrepriseAdvantage.subpriceYearly}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(PlanPricingBody);
